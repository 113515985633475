<template>
    <div class="permission-manager">
        <b-card no-body class="shadow py-3 px-4">
            <b-card-header>
                <span class="h2 font-weight-bold align-self-start">Gestión de contenido Helexium
                </span>

                <!-- {{  data }} -->
            </b-card-header>
            <div class="text-white d-flex flex-row p-2 pt-4">
                <span class="h4 align-self-start font-weight-bold mb-0 mr-4">Id Json:
                    <p class=" font-weight-normal"> {{ data.id }}</p>
                </span>
                <span class="h4 align-self-start font-weight-bold">Fecha registro:
                    <p class=" font-weight-normal"> {{ data.fechaRegistro | timeSince }}</p>
                </span>
            </div>
            <b-card-body>
                <div class="gestionar-layout">
                    <!-- Panel izquierdo (Categorías) -->
                    <div class="left-panel" :class="{ 'mobile-hidden': isMobile && categoria !== null }">
                        <draggable v-model="data.registroJson.data" :move="checkMove" @start="drag = true"
                            @end="drag = false" v-bind="dragOptions" handle=".handle">
                            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                                <div v-for="group in data.registroJson.data" :key="group.groupId" class="category-item"
                                    :class="{ 'active text-uppercase font-weight-bold': categoria === group.groupId }"
                                    @click="selectCategory(group.groupId)">
                                    <div class="d-flex align-items-center p-2 handle">
                                        <b-img :src="group.icono" width="24" height="24" :alt="group.titulo"
                                            class="mr-2"></b-img>
                                        <span>{{ group.titulo }}</span>
                                        <i class="fa-solid fa-x ml-auto"
                                            @click.stop="removeCategory(group.groupId)"></i>
                                    </div>
                                </div>

                            </transition-group>
                        </draggable>
                    </div>

                    <!-- Panel derecho (Contenido de la categoría) -->
                    <div class="right-panel" :class="{ 'mobile-full': isMobile && categoria !== null }">
                        <div v-if="categoria !== null">
                            <div class="category-header">
                                <i v-if="isMobile" class="fa-solid fa-arrow-left mr-3" @click="deselectCategory"></i>
                                <span class=" h2 text-uppercase">{{ selectedCategoryTitle }}</span>
                            </div>
                            <div v-for="group in data.registroJson.data" :key="group.groupId">
                                <div v-if="group.groupId === categoria">
                                    <draggable v-model="group.subGrupo" @start="drag = true" @end="drag = false"
                                        :move="checkMove" v-bind="dragOptions" handle=".handle">
                                        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                                            <div v-for="(subGroup, subGroupIndex) in group.subGrupo" :key="subGroup.id">
                                                <b-card no-body class="mb-3">
                                                    <b-card-header
                                                        v-b-toggle="'collapse-' + manipularTitulos(subGroup.titulo, subGroupIndex)"
                                                        class="d-flex justify-content-between align-items-center"
                                                        @click="colapsar(subGroupIndex)">
                                                        <div>
                                                            <i class="fa fa-align-justify handle mr-2"></i>
                                                            <span class="h5">
                                                                {{ subGroup.titulo === 'Super Admin' &&
                                                                    usuario.rol.nivel !== 4 ? 'Admin' : subGroup.titulo }}
                                                            </span>
                                                        </div>
                                                        <i class="fa-solid fa-x ml-auto mr-4"
                                                            @click.stop="removeSubGroup(group, subGroup)"></i>
                                                        <i
                                                            :class="colapsado[subGroupIndex] ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'"></i>
                                                    </b-card-header>
                                                    <b-collapse
                                                        :id="'collapse-' + manipularTitulos(subGroup.titulo, subGroupIndex)"
                                                        class="mt-2">
                                                        <draggable v-model="subGroup.items" @start="drag = true"
                                                            @end="drag = false" :move="checkMove" v-bind="dragOptions"
                                                            handle=".handle">
                                                            <transition-group type="transition"
                                                                :name="!drag ? 'flip-list' : null">
                                                                <div v-for="(item, itemIndex) in subGroup.items"
                                                                    :key="item.id">
                                                                    <b-card v-if="validarSuperAdmin(item.superAdmin)"
                                                                        class="mb-2">
                                                                        <b-list-group flush>
                                                                            <b-list-group-item
                                                                                class="d-flex justify-content-between align-items-center">
                                                                                <div>
                                                                                    <i
                                                                                        class="fa fa-align-justify handle mr-2"></i>
                                                                                    <span>{{ item.titulo }}</span>
                                                                                </div>
                                                                                <i class="fa-solid fa-x"
                                                                                    @click="removeItem(subGroup, item)"></i>
                                                                            </b-list-group-item>
                                                                        </b-list-group>
                                                                        <div v-if="item.subItems">
                                                                            <b-button
                                                                                v-b-toggle="'collapse-' + manipularTitulos(item.titulo, itemIndex)"
                                                                                size="sm" class="mt-2 mb-2">
                                                                                Ver sub-vistas
                                                                            </b-button>
                                                                            <b-collapse
                                                                                :id="'collapse-' + manipularTitulos(item.titulo, itemIndex)"
                                                                                class="ml-4 mt-1 mb-2 border-left border-info">
                                                                                <draggable v-model="item.subItems"
                                                                                    @start="drag = true"
                                                                                    @end="drag = false"
                                                                                    :move="checkMove"
                                                                                    v-bind="dragOptions"
                                                                                    handle=".handle">
                                                                                    <transition-group type="transition"
                                                                                        :name="!drag ? 'flip-list' : null">
                                                                                        <b-list-group-item
                                                                                            v-for="subItem in item.subItems"
                                                                                            :key="subItem.id"
                                                                                            class="d-flex justify-content-between align-items-center">
                                                                                            <div>
                                                                                                <i
                                                                                                    class="fa fa-align-justify handle mr-2"></i>
                                                                                                <span>{{ subItem.titulo
                                                                                                    }}</span>
                                                                                            </div>
                                                                                            <i class="fa-solid fa-x"
                                                                                                @click="removeSubItem(item, subItem)"></i>
                                                                                        </b-list-group-item>
                                                                                    </transition-group>
                                                                                </draggable>
                                                                            </b-collapse>
                                                                        </div>
                                                                    </b-card>
                                                                </div>
                                                            </transition-group>
                                                        </draggable>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </transition-group>
                                    </draggable>
                                </div>
                            </div>
                        </div>
                        <div v-else class="no-selection">
                            <p>Selecciona una categoría del panel izquierdo</p>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-4">
                    <b-button variant="success" class="mr-2" @click="saveData">
                        <i class="fas fa-save mr-1"></i> Guardar Información
                    </b-button>
                    <b-button variant="danger" @click="cancelar">
                        <i class="fas fa-times mr-1"></i> Cancelar
                    </b-button>
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
// import groupsData from "@/components/partials/backend/HeaderStyle/groupsV2.json";
import Swal from "sweetalert2";

import draggable from 'vuedraggable'

import moment from 'moment/moment';
import moment_timezone from "moment-timezone";
import "moment/locale/es"
moment.locale('es')

export default {
    data() {
        return {
            data: '',
            colapsado: {},
            usuario: '',
            drag: false,
            categoria: '',

        };
    },

    components: {
        draggable

    },

    async mounted() {
        this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
        console.log("EL USUARIO ES" + this.usuario.rol.nivel)
        const personaId = this.$route.params.PersonaId;
        await this.obtenerPersonas(personaId);
        await this.fetchJsonData();

    },


    methods: {


        // MÉTODOS PARA OBTENER Y GUARDAR DATOS DE LA API DE HELEXIUM //
        async fetchJsonData() {
            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "RegistrosJsonControlAccesos/GetLatestJson"
                });
                if (res && res.registroJson) {
                    try {
                        let cleanedJsonString = res.registroJson.replace(/\\"/g, '"').replace(/\\u([0-9a-fA-F]{4})/g, '\\u$1');
                        const parsedJson = JSON.parse(cleanedJsonString);
                        console.log("ESTE ES EL REGISTROOOOOOOOOOOOOOOOO" + parsedJson)
                        this.data = {
                            id: res.id,
                            fechaRegistro: res.fechaRegistro,
                            registroJson: parsedJson
                        };
                    } catch (parseError) {
                        this.data = null;
                    }
                } else {
                    this.data = null;
                }
            } catch (error) {
                this.data = null;
            }
        },

        async obtenerPersonas(personaId) {
            try {
                let res = await this.$store.getters.fetchGet({
                    path: "Persona/GetPersonaById/" + personaId,
                });
                personaId = await res.json();
                console.log("informacion", personaId);
            } catch (error) {
                Swal.fire({
                    title: "Error",
                    text: "Por favor recarga la pagina",
                    icon: "error",
                    confirmButtonText: "Voy a recargar la pagina",
                });
                //this.Persona = {};
                console.log("informacion error", error);
            }
        },
        async saveData() {
            try {
                let mapa = JSON.stringify(this.data.registroJson)
                console.log("ESTE ES EL REGISTROOOOOOOOOOOOOOOOOMAPA" + mapa)
                // Escapar las comillas en la cadena para obtener el formato escapado


                const response = await this.$store.dispatch("hl_post", {
                    path: `RegistrosJsonControlAccesos/CreateJson`,
                    method: 'POST',
                    data: mapa
                });

                if (response) {
                    this.showSweetAlert('Éxito', 'Los permisos del Rol han sido actualizados correctamente.', 'success');
                    this.fetchJsonData();

                } else {
                    throw new Error('La respuesta del servidor no fue exitosa');
                }
            } catch (error) {
                console.error("Error al guardar los cambios:", error);
                this.showSweetAlert('Error', 'Hubo un problema al guardar los cambios. Por favor, intenta de nuevo.', 'error');
            }
        },

        // MÉTODOS PARA ORDENAR Y GESTIONAR INFORMACIÓN DE LA VISTA //
        cancelar() {
            this.$router.push('/dashboard');
        },
        checkMove: function (e) {
            window.console.log("Future index: " + e.draggedContext.futureIndex);
        },
        showSweetAlert(title, text, icon) {
            Swal.fire({
                title: title,
                text: text,
                icon: icon,
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
            });
        },
        manipularTitulos(title, index) {

            return `${title.toLowerCase().replace(/[^a-z0-9]+/g, "-")}-${index}`;
        },
        manipularTitulosCategorias(title, index) {
            this.categoria = `${title.toLowerCase().replace(/[^a-z0-9]+/g, "-")}-${index}`

        },
        colapsar(subGroupIndex) {
            this.$set(this.colapsado, subGroupIndex, !this.colapsado[subGroupIndex]);
        },
        validarSuperAdmin(item) {
            if (!item) {
                return true;
            }
            if (this.usuario.rol.id === "4a93a921-6473-4a45-8aba-0f2da1cd7724" || this.usuario.rol.id === "313D1A7C-52F9-4448-A591-0775AA7F66D4") {
                console.log("Este usuario es Super Admin", this.usuario.rol.id);
                return true;
            }
            return false;
        },
        removeCategory(groupId) {
            Swal.fire({
                title: "¡Atención!",
                text: "Estas a punto de eliminar esta categoria del sistema, ¿estás seguro?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Estoy seguro",
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    // console.log("confirmado");
                    const index = this.data.registroJson.data.findIndex(item => item.groupId === groupId)
                    if (index !== -1) {
                        this.data.registroJson.data.splice(index, 1)
                    }
                }
            });
        },
        removeSubGroup(group, subGroup) {
            Swal.fire({
                title: "¡Atención!",
                text: "Estas a punto de eliminar esta vista del sistema, ¿estás seguro?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Estoy seguro",
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    // console.log("confirmado");
                    const index = group.subGrupo.findIndex(i => i.id === subGroup.id)
                    if (index !== -1) {
                        group.subGrupo.splice(index, 1)
                    }
                }
            });
        },
        removeItem(subGroup, item) {
            Swal.fire({
                title: "¡Atención!",
                text: "Estas a punto de eliminar esta vista del sistema, ¿estás seguro?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Estoy seguro",
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    // console.log("confirmado");
                    const index = subGroup.items.findIndex(i => i.id === item.id)
                    if (index !== -1) {
                        subGroup.items.splice(index, 1)
                    }
                }
            });
        },
        removeSubItem(item, subItem) {

            Swal.fire({
                title: "¡Atención!",
                text: "Estas a punto de eliminar esta sub vista del sistema, ¿estás seguro?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Estoy seguro",
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    // console.log("confirmado");
                    const index = item.subItems.findIndex(i => i.id === subItem.id)
                    if (index !== -1) {
                        item.subItems.splice(index, 1)
                    }
                }
            });


        },
        selectCategory(groupId) {
            this.categoria = groupId
        },
        deselectCategory() {
            this.categoria = null
        },
    },

    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        isMobile() {
            return window.innerWidth < 768
        },
        selectedCategoryTitle() {
            const category = this.data.registroJson.data.find(group => group.groupId === this.categoria)
            return category ? category.titulo : ''
        },


    },
    filters: {
        formatDate(date) {

            return date ? moment(date).locale("es").format('D MMM YYYY') : "No hay fecha registrada";
        },
        timeSince(dateString, timezone = "America/Bogota") {
            const dateInBogota = moment_timezone.tz(dateString, timezone);
            return dateInBogota.format("D MMM YYYY hh:mm A");
        },
    },
}


</script>

<style scoped>
.card-body {
    padding: 0px !important;
}

.permission-manager {
    max-width: 1200px;
    margin: 0 auto;
}

.selected-person-info {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 5px;
}

.b-tabs.card.vertical {
    border: none;
}

.tab-content {
    padding-left: 20px;
}

.list-group-item {
    transition: background-color 0.2s ease;
}

.list-group-item:hover {
    background-color: #f8f9fa;
}

.btn-group-sm>.btn {
    padding: 0.25rem 0.5rem;
}

.badge {
    font-size: 0.9em;
}

.mapa-acceso-container {
    max-height: 200px;
    overflow-y: auto;
    background-color: #2167ff;
    border: 1px solid #dee2e6;
    border-radius: 5px;
}

.mapa-acceso {
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 10px;
    margin: 0;
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.handle {
    cursor: pointer !important;
}

/* ESTILOS MENU DE GESTION DE VISTAS DE HELEXIUM */
.gestionar-layout {
    display: flex;
    height: 60vh;
    overflow: hidden;
}

.left-panel {
    width: 300px;
    overflow-y: auto;
    border-right: 1px solid #d1d1d1;
    transition: all 0.3s ease;
}

.right-panel {
    flex-grow: 1;
    padding: 5px 20px 20px 20px;
    overflow-y: auto;
    transition: all 0.3s ease;

}

.category-item {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
}

.category-item:hover {
    background-color: #085dc55e;
}

.category-item.active {
    background-color: #085ec5;
}

.category-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #d1d1d142;
}



.no-selection {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 18px;
    color: #888;
}

.handle {
    cursor: move;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.flip-list-move {
    transition: transform 0.5s;
}

@media (max-width: 767px) {
    .gestionar-layout {
        flex-direction: column;
    }

    .left-panel {
        width: 100%;
        height: 100vh;
    }

    .right-panel {
        width: 100%;
        height: 100vh;
    }

    .mobile-hidden {
        display: none;
    }

    .mobile-full {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        background: white;
    }
}
</style>